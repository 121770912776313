.search-input-div {
    /* display: flex;
    flex-direction: row; */
    align-items: flex-end;
    overflow: hidden;
    width: 100%;
    border: none;
    /* margin-left: 10px; */
    border-width: 0;
    border-radius: 8px;
}
.captureLocationPage {
    margin-top:3rem;
}
.captureLocationPage .search-input-div {
    border: 1px solid #000 !important;
    width: 80% !important;
    margin: 1rem 10% !important;
}
.preference-heading-title {
    padding: 0 10%;
}
.search-box {
    width: 100%;
    border-width: 0;
    outline: none;
    height: 50px;
    padding-left: 1rem;
    font-size: 1rem;
    border: none;
}
.search-options {
    border-width: 0;
    width: 100%;
    margin-left: 1rem;
}

.suggestion-item {
    padding: 0.5em;
}

.react-autosuggest__suggestions-list {
    margin:0;
    list-style: none;
}

.react-autosuggest__suggestion--highlighted {
    background-color: rgba(255, 255, 255, 0.625);
}

.mitralogo {
    margin: 0 auto;
    margin-top: 20px;
    display: block;
    width: 100px;
}
