@media screen and (min-width: 576px) {
	/* .wrapper-input-one {
    width: 95% !important;
  } */
	.video-message .wrapper-message-left {
		width: 560px;
		height: 315px;
	}
	.modal-middle {
		display: flex !important;
		justify-content: center !important;
	}
	.jss1 {
		right: 4% !important;
	}
	/* .message-input-box {
    width:  100% !important;
  } */
	.jss3 {
		margin-left: 60% !important;
	}
	.userImageRotate img {
		-ms-transform: rotate(0deg) !important;
		-webkit-transform: rotate(0deg) !important;
		transform: rotate(0deg) !important;
	}
}
/* .message-input-box input[type=text]:focus{
  outline: 2px solid #6e6e6e !important;
} */
.headerBox {
	color: rgba(0, 0, 0, 0.87);
	background: #222f3e;
	/* margin-top: 1rem; */
}
.mdc-tab .mdc-tab__text-label {
	color: #bbbfc3;
}
.mdc-tab--active {
	background: #eebb25;
}
.mdc-tab--active .mdc-tab__text-label {
	color: #3f3f3f;
	font-weight: 600;
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
	border-color: var(--mdc-theme-primary, rgba(0, 0, 0, 0.87));
}
.MuiIconButton-colorPrimary {
	color: rgba(0, 0, 0, 0.54) !important;
}
/* .message-input-box input[type=text]:focus + .jss1{
  top: 90% !important;
  margin-right: 2rem !important;
} */
.message-input-box {
	border: 1px solid #eebb25 !important;
	margin-left: 1rem;
	/* margin-right: 1rem; */
	border-radius: 8px;
	background: white;
	/* height: 60px; */
	/* display: table; */
	/* width: 100%; */
}
.jss1 {
	vertical-align: middle;
	display: table-cell;
	margin: 0px !important;
	/* top: 90%; */
	right: 15%;
	position: absolute !important;
}
.jss3 {
	margin-bottom: -5% !important;
	margin-left: 6px;
	background: #eeae1f;
	border-radius: 50%;
	color: #a70505;
	position: relative;
	padding: 12px;
	align-items: center;
	justify-content: center;
}
.mdc-text-field {
	height: 50px !important;
	margin-left: 1rem !important;
}
.wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	grid-template-rows: auto 1fr auto;
	background-image: url(./chatBG_cropped_min.jpg);
}
.text-message {
	font-size: 15px;
	border-radius: 8px;
	display: inline-block;
	height: auto;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-right: 1rem;
	padding: 1rem;
	position: relative;
	width: auto;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}
.text-message2 {
	font-size: 15px;
	border-radius: 8px;
	background: #f0f3f6;
	display: inline-block;
	height: auto;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-right: 0.4rem;
	padding: 1rem;
	position: relative;
	width: auto;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}
.thumbnail-message {
	border-radius: 8px;
	display: inline-block;
	height: auto;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-right: 1rem;
	padding: 0.2rem;
	position: relative;
	width: 166px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
	background: #f9eab5;
}
.thumbnail-message .thumbnail-link {
	color: inherit;
	text-decoration: none;
}
.thumbnail-message .thumbnail-text {
	margin: 0.2rem;
	overflow-wrap: break-word;
	word-wrap: break-word;
}
.wrapper-message-right .text-message {
	padding: 0 1em !important;
	background: #f9eab5;
}
.wrapper-message-left .text-message {
	padding: 0 1em !important;
	background: #f0f3f6;
}
/* .one-block {
  background: #e9dcc2;
} */
.wrapper-input {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	overflow: hidden;
	width: 100%;
	margin-bottom: 1vh;
	margin-top: 4px;
	/* height: 50px; */
	/* z-index: 2; */
	/* bottom: 2%; */
	/* position: absolute; */
}
.wrapper-input #icon-button-file {
	z-index: 9;
	padding-left: 20px;
}
.wrapper-input-one {
	flex-grow: 1;
	/* width: 84%; */
}
.mdc-text-field .mdc-line-ripple,
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
	.mdc-text-field__input {
	background-color: #fff !important;
	border-bottom-color: #fff !important;
}
.wrapper-input-box {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	overflow: hidden;
	width: 100%;
	border: none;
	/* margin-bottom: 1rem; */
	/* height: 50px; */
	/* z-index: 2; */
	/* bottom: 2%; */
	/* position: absolute; */
}
/* .message-textbox{
  border: none;
  border-color: none;
  outline: none;
  border-style: none;
  border-width: 0;
} */
.message-send-button {
	margin-left: 1rem;
	margin-right: 1rem;
	align-self: center;
	align-items: center;
	padding: 0px;
	width: 26px;
	height: 26px;
	line-height: 26px;
	border-radius: 26px;
	background-color: #eebb25;
	border: solid 12px #eebb25;
	padding-right: 0.15rem;
	padding-left: 0.3rem;
	padding-top: 0.01rem;
	padding-bottom: 0.1rem;
}
.message-camera-button {
	margin-left: 1rem;
}
.wrapper-message-left {
	display: flex;
	flex-direction: row;
}
.wrapper-message-right {
	display: flex;
	flex-direction: row-reverse;
}
/* .text-message-left {
  background: #f0f3f6;
}
.text-message-right {
  background: #f9eab5;
} */
.video-message {
	margin: 1rem;
}
.message-list {
	overflow-x: hidden;
	overflow-y: auto;
}
.button-message {
	margin: 1rem;
}
.wrapper-cards {
	display: flex;
	flex-direction: row;
	/* align-items: center; */
	justify-content: center;
	width: 100%;
	background: #d5d5d5;
	height: 100%;
}
.wrapper-myjobsprofile {
	display: grid;
	width: 100%;
	height: 85vh;
	grid-template-columns: 100%;
	grid-template-rows: auto 90%;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
}
div.serachInputDiv {
	text-align: left;
	margin-left: 1em;
}
div.serachInputDiv .searchplaces {
	padding: 1em 7em 1em 0.8em;
}
div.autocomplete-dropdown-container {
	font-size: 0.8rem;
	font-weight: 300;
	text-align: left;
}
div.autocomplete-dropdown-container .suggestion-item {
	margin-top: 1em;
	text-decoration: underline;
}
.suggestion-item {
	text-decoration: underline;
	/* margin-top: 1em; */
}
.button-message .wrapper-message-right button,
.jss3 {
	background: #eebb25;
}
.button-message .wrapper-message-right button {
	color: #000000;
	border-radius: 18px;
	/* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14); */
}
.container {
	display: flex;
	flex-direction: column;
	height: 90vh;
	/* max-height: 90%; */
	overflow-y: hidden;
}
.container-section {
	margin: 5px;
	flex-grow: 1;
	/* max-height: 95%; */
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
}
.container-section-body {
	flex-grow: 1;
	overflow-y: auto;
}
.container-section-nav {
	order: -1;
}
.main-container {
	text-align: center;
	height: 100%;
	display: table;
	width: 100%;
	font-size: 2rem;
	background: #d5d5d5;
}
.main-container span {
	vertical-align: middle;
	display: table-cell;
}
.userImageSource img,
.userImageRotate img {
	height: auto !important;
	width: 270px !important;
	padding: 0 14px;
}
.userImageRotate img {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.wrapper-jobs-profile {
	display: grid;
	width: 100%;
	grid-template-columns: 100%;
	height: 100vh;
	grid-template-rows: auto 1fr;
}
.spam-button-block {
	width: 50%;
	opacity: 0.4;
	background: #a5a5a5;
	background-color: var(--mdc-theme-primary, #a8a7a7) !important;
	font-size: 1rem;
	font-weight: 1000 !important;
	color: #000000 !important;
}
.mdc-tab .mdc-tab__icon {
	color: #bbbfc3;
}
.mdc-tab--active .mdc-tab__icon {
	color: #3f3f3f;
}
.otp-popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
}
.otp-popup-inner {
	position: absolute;
	left: 1px;
	right: 1px;
	top: 65%;
	border-radius: 8px;
	bottom: 1px;
	margin: auto;
	background: white;
}
.otp-popup-padding {
	padding: 2rem;
}
.otp-popup-message {
	text-align: center;
}
.otp-popup-phone {
	padding: 2rem;
	text-align: center;
}
.otp-popup-button {
	text-align: center;
}
.otp-popup-button .mdc-button--raised:not(:disabled) {
	background-color: #eebb25;
	color: #2d6400;
}
.otp-popup-button-edit {
	text-align: center;
	/* height: 20px;
  width: 40px; */
}
.otp-popup-button-edit .mdc-button--raised:not(:disabled) {
	background-color: #eebb25;
	color: #000000;
}
.otp-popup-code-flex {
	display: flex;
	justify-content: center;
	width: 100%;
}
.otp-popup-code-box {
	padding: 2rem;
}
.otp-popup-code {
	text-align: center;
	width: 3rem;
}
.mdc-top-app-bar {
	background-color: #222f3e !important;
}
.mdc-top-app-bar__title {
	line-height: 3rem !important;
}
.topbar-action-icon {
	background-repeat: no-repeat;
	background-position: center;
}
.round-button {
	/* display:block; */
	background-color: #eebb25;
	border: none;
	/* height: 300px;
  width: 300px; */
	margin-left: 0rem;
	margin-top: 1rem;
	margin-right: 1rem;
	/* padding: 0 1em !important; */
	padding-right: 0.8rem;
	padding-left: 0.7rem;
	padding-top: 0.7rem;
	padding-bottom: 0.6rem;
	border-radius: 50%;
	width: auto;
	box-shadow: 0px 1px 0px 0px grey;
}
.rating {
	font-size: 50px;
	text-align: center;
}
.rating label {
	padding-top: 1rem;
	color: #c7c7c7 !important;
}
.rating-text {
	text-align: center;
	font-size: 1rem;
}

.jobrating-text {
	font-size: 25px;
}

.text-area-center {
	text-align: center;
	background: #e7e7e7;
	border-color: #e7e7e7;
}

.jobRating-text {
	background: #e7e7e7;
	border-color: #e7e7e7;
}
.button-center {
	/* border-radius: 30%;
  font-size: 1.3rem;
  padding: 0 2rem;
  height:60px; */
	text-align: center;
	width: 100px;
	background-color: #eebb25;
	color: #000000;
}
.button-close {
	font-size: 10px;
	text-align: center;
	height: 40px;
	width: 100px;
	background-color: #eebb25;
	color: #2d6400;
}
.feedback-button-wrapper-input {
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
	overflow: hidden;
	width: 100%;
}
.feedback-button-wrapper-input-button {
	flex-grow: 0;
	font-size: 2rem;
	border-radius: 50%;
	padding: 0 0.5rem;
}
.feedback-form-wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
}
.feedback-form-input-wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
	width: 100%;
}
.feedback-form-input-wrapper-input-box {
	/* flex-grow: 0; */
	align-self: center;
}
.multiple-messages-wrapper {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.multiple-messages-indv-wrapper {
	/* flex-grow: 1; */
	flex-shrink: 1;
	/* margin: '1rem';
  background: '#eebb25';
  color: '#000000';
  border-radius: '18px'; */
}

/* The Modal (background) */
.modal {
	display: grid;
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.file-upload-button {
	padding: 10px;
	padding-left: 40px;
	padding-right: 40px;
	background: #eebb25;
	display: table;
	color: #000000;
}

.file-upload-input {
	display: none;
}

.modal-header {
	display: table;
	margin: auto;
}

.modal-middle {
	display: table;
	margin: auto;
}

.modal-footer {
	display: table;
	margin: auto;
	padding: 15px;
}

.id-upload-failed {
	color: #a70505;
}

.id-upload-successful {
	color: #2d6400;
}
.doc-header-camera-wrapper {
	display: flex;
}
.doc-header {
	height: 38px;
	width: 188px;
	color: rgba(255, 255, 255, 0.87);
	font-size: 14px;
	letter-spacing: 0;
	margin-left: 0.7rem;
}
.camera-icon {
	height: 39px;
	width: 36px;
	margin-left: 2rem;
	margin-top: 0.8rem;
}
.back-arrow {
	height: 24px;
	width: 24px;
	margin-right: 0.5rem;
	margin-top: 5px;
}
.arrow-icon-wrapper {
	display: flex;
	margin-left: 5px;
}
a {
	color:inherit;
	text-decoration: none;
}

.react-autosuggest__suggestions-list {
	margin:0;
	list-style: none;
}

.react-autosuggest__suggestion--highlighted {
	background-color: rgba(255, 255, 255, 0.625);
}