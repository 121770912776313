.dlImageFront {
	background-image: url(./imagePlaceholder/DLFront_placeholder.png);
}
.dlImageBack {
	background-image: url(./imagePlaceholder/DLBack_placeholder.png);
}

.aadhaarFront {
	background-image: url(./imagePlaceholder/AadhaarFront_placeholder.png);
}

.aadhaarBack {
	background-image: url(./imagePlaceholder/AadhaarBack_placeholder.png);
}

.panCard {
	background-image: url(./imagePlaceholder/pan_placeholder.png);
}

.rc {
	background-image: url(./imagePlaceholder/RC_placeholder.png);
}

.bankPassbook {
	background-image: url(./imagePlaceholder/BankPassbook_placeholder.png);
}

.resume {
	background-image: url('./imagePlaceholder/Resume_placeholder.png');
}

.cancelledCheque {
	background-image: url(./imagePlaceholder/Cheque_placeholder.png);
}

.filesubcomponent {
	box-sizing: border-box;
	height: 158px;
	width: 158px;
	border: 1px dashed #d1d1d1;
	border-radius: 8px;
	background-color: #eeeeee;
}
.styleHeader {
	height: 30px;
	color: black;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0px;
	line-height: 27px;
	text-align: center;
}
.imageHolder {
	height: 101px;
	width: 156px;
}
.iconStyle {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	position: relative;
	left: 38%;
	bottom: 16%;
	text-align: center;
	background-color: rgb(255, 255, 255);
	font-size: 35px;
	color: #f0bc00;
}
.inputFile {
	display: none;
}

.deleteIcon {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	position: relative;
	left: 38%;
	bottom: 16%;
	text-align: center;
	background-color: rgb(255, 255, 255);
	font-size: 35px;
	color: #f0bc00;
}
.deleteModalTextStyle {
	height: 28px;
	width: 124px;
	color: rgba(0, 0, 0, 0.87);
	font-family: Roboto;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0px;
	line-height: 28px;
	padding: 15px;
}
.buttonStyle {
	height: 36px;
	width: 75px;
	color: rgb(0, 150, 136);
	font-family: Roboto;
	font-size: 14px;
	font-weight: 600;
	float: right;
	border: 0px;
	padding-right: 10px;
	line-height: 50px;
	background-color: rgb(255, 255, 255);
}
button:focus {
	outline: 0;
}
.filemaincontainer {
	display: grid;
	gap: 10px;
	grid-template-columns: auto auto;
	padding-left: 10px;
}
.progressBarContainer {
	display: flex;
	overflow: hidden;
	line-height: 0;
	font-size: .75rem;
	background-color: #fff;
	border-radius: .25rem;
	width: 90%;
	height: 10px;
	margin: 5px;
}
.progressheader {
	display: flex;
	background-color: #e9ecef;
	margin-bottom: 15px;
	height: 24px;
}
.progressText {
	font-size: 15px;
	padding: 2px;
	font-weight: 600;
	color: #097b15;
}

.actualImagestyle {
	width: inherit;
	height: inherit;
	position: absolute;
}
.progress-bar-striped {
	background-image: linear-gradient(
		135deg,
		#fff 25%,
		transparent 25%,
		transparent 50%,
		#fff 50%,
		#fff 75%,
		transparent 75%,
		transparent
	);
	background-size: 1rem 1rem;
}
.progress-bar {
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	height: 6px;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #67d466;
	border: 2px solid #67d466;
	transition: width .6s ease;
}
.messageoverlay {
	height: 60%;
	background: #f0bc00;
	opacity: 0.3;
}
.messageText {
	font-size: 1.2em;
	font-weight: 500;
	position: relative;
	top: -80%;
	left: 35%;
	width: 65%;
	height: 80%;
	overflow: hidden;
	font-family: 'Open sans-serif';
}
.errorMessageText {
	font-size: 0.9em;
	font-weight: 600;
	position: relative;
	top: -54%;
	left: 3%;
	color: orangered;
	height: 80%;
	overflow: hidden;
	font-family: 'Open sans-serif';
}
.dl-title-wrapper {
	display: flex;
	justify-content: center;
}
.check-tick {
	color: #097b15;
	margin-top: 0.4rem;
	margin-left: 5px;
}
.loader {
	top: -45%;
	left: 5%;
	border: 5px solid transparent;
	border-radius: 50%;
	border-top: 5px solid #f0bc00;
	border-left: 5px solid #f0bc00;
	border-right: 5px solid #f0bc00;
	position: relative;
	width: 1.9em;
	height: 1.9em;
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@media only screen and (min-width: 768px) {
	.filemaincontainer {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
	}
	.progressBarContainer {
		width: 97%;
	}
}
